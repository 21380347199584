import { UpdateRoomRequest } from './../models/update-room-request';
import { APIConstants } from './../common/API-Constants';
import { VideoRoomDB } from './../models/video-room';
import { AccessTokenRequest } from './../models/access-token-request';
import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

interface AuthToken {
  token: string;
}

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
  providedIn: 'root',
})
export class VideoChatService {
  $roomsUpdated: Observable<boolean>;

  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(private readonly http: HttpClient) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  private async getAuthToken(roomName: string, participantIdentity: string) {
    const body: AccessTokenRequest = {
      roomName: roomName,
      participantName: participantIdentity,
    };
    debugger
    let url = APIConstants.BASE_URL + `api/V1/Video/GetToken/token`;
    const auth = await this.http
      .post<AuthToken>(url, body)
      .toPromise();

    return auth.token;
  }

  getAllRooms() {
    var url= APIConstants.BASE_URL + "api/V1/Video/GetRooms/rooms";
    return this.http.get<Rooms>(url).toPromise();
  }

  getConfig(){
   var url= APIConstants.BASE_URL + "api/V1/Config/GetConfig/GetClientConfig";
    return this.http.get(url).toPromise();
  }

  async joinOrCreateRoom(
    name: string,
    tracks: LocalTrack[],
    participantIdentity: string
  ) {
    let room: Room = null;
    try {
      const token = await this.getAuthToken(name, participantIdentity);
      debugger
      room = await connect(token, {
        name,
        tracks,
        dominantSpeaker: true,
        logLevel: 'debug',
      } as ConnectOptions);
    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
    } finally {
      if (room) {
        this.roomBroadcast.next(true);
      }
    }

    return room;
  }

  async getRoomById(roomId: string): Promise<VideoRoomDB> {
    const url = APIConstants.BASE_URL + APIConstants.GET_ROOM_BY_ID + roomId;
    const room = await this.http.get<VideoRoomDB>(url).toPromise();
    return room;
  }

  async updateRoom(roomToBeUpdated: UpdateRoomRequest): Promise<VideoRoomDB> {
    const url = APIConstants.BASE_URL + APIConstants.UPDATE_ROOM;
    const room = await this.http
      .post<VideoRoomDB>(url, roomToBeUpdated)
      .toPromise();
    return room;
  }

  async completeMeeting(sid: string) {
    const url = APIConstants.BASE_URL + APIConstants.COMPLETE_MEETING + sid;
    const message = await this.http.post(url, null).toPromise();
    return message;
  }

  nudge() {
    this.roomBroadcast.next(true);
  }
}
