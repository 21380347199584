<div class="container-fluid">
  <mat-card class="m-2 border">
    <h4>Settings</h4>
    <form class="form">
      <div class="form-group" *ngIf="hasAudioInputOptions">
        <app-device-select
          [kind]="'audioinput'"
          [label]="'Audio Input Source'"
          [devices]="devices"
          (settingsChanged)="onSettingsChanged($event)"
        ></app-device-select>
      </div>
      <div class="form-group" *ngIf="hasAudioOutputOptions">
        <app-device-select
          [kind]="'audiooutput'"
          [label]="'Audio Output Source'"
          [devices]="devices"
          (settingsChanged)="onSettingsChanged($event)"
        ></app-device-select>
      </div>
      <div class="form-group" *ngIf="hasVideoInputOptions">
        <app-device-select
          [kind]="'videoinput'"
          #videoSelect
          [label]="'Video Input Source'"
          [devices]="devices"
          (settingsChanged)="onSettingsChanged($event)"
        ></app-device-select>
      </div>
    </form>
    <div class="setting-preview" [style.display]="isPreviewing ? 'block' : 'none'">
      <app-camera #camera></app-camera>
    </div>
  </mat-card>
</div>
