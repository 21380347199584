import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-camera',
  styleUrls: ['./camera.component.css'],
  templateUrl: './camera.component.html',
})
export class CameraComponent implements AfterViewInit {
  @ViewChild('preview', { static: false }) previewElement: ElementRef;

  isInitializing: boolean = true;
  videoTrack: LocalVideoTrack = null;
  videoElement: HTMLVideoElement;

  constructor(
    private readonly storageService: StorageService,
    private readonly renderer: Renderer2
  ) {}

  async ngAfterViewInit() {
    if (this.previewElement && this.previewElement.nativeElement) {
      const selectedVideoInput = this.storageService.get('videoInputId');
      await this.initializeDevice(selectedVideoInput);
    }
  }

  async initializePreview(deviceId: string) {
    await this.initializeDevice(deviceId);
  }

  finalizePreview() {
    try {
      if (this.videoTrack) {
        this.videoTrack.detach().forEach((element) => element.remove());
      }
      this.videoTrack = null;
    } catch (e) {
      console.error(e);
    }
  }

  private async initializeDevice(deviceId?: string) {
    try {
      this.isInitializing = true;
      this.finalizePreview();

      this.videoTrack = deviceId
        ? await createLocalVideoTrack({ deviceId })
        : await createLocalVideoTrack();

       this.videoElement = this.videoTrack.attach();
      //this.renderer.setStyle(videoElement, 'height', '80%');
      // this.renderer.setStyle(videoElement, 'max-width', '100vw');
      //this.renderer.addClass(videoElement, 'w-75');
      //this.renderer.addClass(videoElement, 'w-md-50');
      // videoElement.classList.add('vh-100');
      this.renderer.appendChild(
        this.previewElement.nativeElement,
        this.videoElement
      );
    } finally {
      this.isInitializing = false;
    }
  }

}
