import { Component, Input, OnInit } from '@angular/core';
import { RemoteParticipant } from 'twilio-video';

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss'],
})
export class ParticipantsListComponent implements OnInit {
  @Input() participantsList: Map<string, RemoteParticipant>;
  @Input() localParticipantName: string;
  constructor() {}

  ngOnInit() {}
}
