import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-indicator',
  templateUrl: './activity-indicator.component.html',
  styleUrls: ['./activity-indicator.component.css']
})
export class ActivityIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
