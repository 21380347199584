import { ConfigConstants } from './../common/config';
import { VideoRoomDB } from './../models/video-room';
import { UpdateRoomRequest } from './../models/update-room-request';
import {
  Component,
  ViewChild,
  OnInit,
  Renderer2,
  ElementRef,
  Inject,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import {
  createLocalAudioTrack,
  Room,
  LocalTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Participant,
  RemoteTrackPublication,
  createLocalVideoTrack,
} from 'twilio-video';
import { RoomsComponent } from '../rooms/rooms.component';
import { CameraComponent } from '../camera/camera.component';
import { SettingsComponent } from '../settings/settings.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { VideoChatService } from '../services/videochat.service';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { ActivatedRoute } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import * as moment from 'moment';
import { faComments, faInfo, faMicrophone, faMicrophoneSlash, faPhoneSlash, faShareSquare, faTimes, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { APIConstants } from '../common/API-Constants';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.css'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  @ViewChild('rooms', { static: false }) rooms: RoomsComponent;
  @ViewChild('camera', { static: false }) camera: CameraComponent;
  @ViewChild('settings', { static: false }) settings: SettingsComponent;
  @ViewChild('screen', { static: false }) screen: ElementRef;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild('localVideo', { static: false }) localVideo: ElementRef;
  @ViewChild('remoteVideosContainer', { static: false }) remoteVideosContainer: ElementRef;
  @ViewChild('participants', { static: false })

  isMuted: boolean = false;
  isVideo: boolean = false;
  deviceId: any;
  localParticipants: any;


  mute() {
    this.isMuted = !this.isMuted
  }

  video() {
    this.isVideo = !this.isVideo
  }

  toggleFullScreen() {
    const doc = document.documentElement;
    if (!document.fullscreenElement) {
      doc.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  //-------ICONS---------------
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faVideo = faVideo;
  faVideoSlash = faVideoSlash;
  faShareSquare = faShareSquare;
  faComments = faComments;
  faInfo = faInfo;
  faPhoneSlash = faPhoneSlash;
  faTimes = faTimes
  //---------------------------

  //participants: ParticipantsComponent;
  countdownConfig: CountdownConfig;
  hasMeetingStarted: boolean = false;

  showPreviewOrSharedScreen: boolean = true;
  activeRoom: Room;
  queryRoomName: string;
  roomGuid: string;
  participantName: string;

  isSharingScreen: boolean = false;
  isAudioEnabled: boolean = true;
  isVideoEnabled: boolean = true;

  private notificationHub: HubConnection;

  //---------Formerly in Participants Component---------
  @ViewChild('list', { static: false }) listRef: ElementRef;
  // @Output('participantsChanged') participantsChanged = new EventEmitter<
  //   boolean
  // >();
  // @Output('leaveRoom') leaveRoom = new EventEmitter<boolean>();
  // @Input('activeRoomName') activeRoomName: string;

  get participantCount() {
    return !!this.participants ? this.participants.size : 0;
  }

  get isAlone() {
    return this.participantCount === 0;
  }

  private participants: Map<Participant.SID, RemoteParticipant>;
  private dominantSpeaker: RemoteParticipant;
  //----------------------------------------------------

  constructor(
    private readonly videoChatService: VideoChatService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly renderer: Renderer2,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private iconLibrary: FaIconLibrary
  ) {
    iconLibrary.addIcons(faMicrophone, faMicrophoneSlash)
  }

  async ngOnInit() {
    try {
      this.videoChatService.getConfig().then((data: any) => {
        APIConstants.BASE_URL = data.baseUrl;
      })

      const builder = new HubConnectionBuilder()
        .configureLogging(LogLevel.Information)
        .withUrl(`${APIConstants.BASE_URL}notificationHub`);

      this.notificationHub = builder.build();

      this.notificationHub.on('RoomsUpdated', async (updated) => {
        if (updated) {
          await this.rooms.updateRooms();
        }
      });

      await this.notificationHub.start();

      this.route.firstChild.params.subscribe((params) => {
        this.roomGuid = params['roomId'];
      });

      const dialogRef = this.dialog.open(IdentityDialogComponent, {
        disableClose: true,
        panelClass: 'd-inline-flex',
        width: '500px',
        height: '218px',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        this.participantName = result;
        if (this.roomGuid != null) {
          const room = await this.videoChatService.getRoomById(this.roomGuid);
          if (room.endTime && moment.utc(room.endTime) < moment.utc()) {
            console.log(moment.utc());
            this.dialog.open(MessageDialogComponent, {
              data: {
                title: 'Meeting Ended',
                message: 'This meeting has already ended',
              },
              panelClass: 'd-inline-flex',
              height: '200px'
            });
          } else {
            this.onRoomChanged(room);
          }
        }
      });
    } catch (error) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title: 'Error',
          message: 'An error occured. Please try again in some time.',
        },
        panelClass: 'd-inline-flex',
        height: '200px'
      });
    }
  }

  //---------Formerly in participants component--------
  clear() {
    if (this.participants) {
      this.participants.clear();
    }
  }

  initialize(participants: Map<Participant.SID, RemoteParticipant>) {
    this.participants = participants;
    if (this.participants) {
      this.participants.forEach((participant) => {
        this.registerParticipantEvents(participant);
        for (const track of participant.videoTracks.values()) {
          this.attachRemoteTrack(track.track);
        }
        for (const track of participant.audioTracks.values()) {
          this.attachRemoteTrack(track.track);
        }
      });
    }
  }

  add(participant: RemoteParticipant) {
    if (this.participants && participant) {
      this.participants.set(participant.sid, participant);
      this.registerParticipantEvents(participant);
      let message = `${participant.identity} has joined the meeting`;
      this.snackBar.open(message, 'OK', { duration: 1000 });
    }
  }

  remove(participant: RemoteParticipant) {
    if (this.participants && this.participants.has(participant.sid)) {
      this.participants.delete(participant.sid);
      let message = `${participant.identity} has left the meeting`;
      this.snackBar.open(message, 'OK', { duration: 1000 });
    }
  }

  loudest(participant: RemoteParticipant) {
    this.dominantSpeaker = participant;
    //this.displayDominantSpeaker(participant);
  }

  displayDominantSpeaker(participant: RemoteParticipant) {
    for (const track of participant.videoTracks.values()) {
      //Only show the loudest participant when the screen is not being shared
      if (track.track.name != 'screen') {
        if (this.isAttachableRemote(track.track)) {
          // //Remove the loudest participants when screen is shared
          // const childElements = this.screen.nativeElement.children;
          // for (let child of childElements) {
          //   this.renderer.removeChild(this.screen.nativeElement, child);
          // }
          const element = track.track.attach();
          this.renderer.data.id = track.track.sid;
          //this.renderer.addClass(element, 'col-12 text-center');
          this.screen.nativeElement.classList.add('col-12', 'text-center');
          //this.renderer.setStyle(element, 'width', '95%');
          // this.renderer.setStyle(element, 'margin-left', '2.5%');
          element.classList.add('mw-100');
          this.renderer.appendChild(this.screen.nativeElement, element);
        }
      }
    }
  }

  private registerParticipantEvents(participant: RemoteParticipant) {
    if (participant) {
      participant.tracks.forEach((publication) => this.subscribe(publication));
      participant.on('trackPublished', (publication) =>
        this.subscribe(publication)
      );
      participant.on('trackUnpublished', (publication) => {
        if (publication && publication.track) {
          this.detachRemoteTrack(publication.track);
        }
      });
    }
  }

  private subscribe(publication: RemoteTrackPublication | any) {
    if (publication && publication.on) {
      publication.on('subscribed', (track) => this.attachRemoteTrack(track));
      publication.on('unsubscribed', (track) => this.detachRemoteTrack(track));
    }
  }

  private attachRemoteTrack(track: RemoteTrack) {
    if (this.isAttachable(track)) {
      if (track.name == 'screen') {
        this.subscribeScreenShare(track);
      } else {
        const element = track.attach();
        this.renderer.data.id = track.sid;
        if (this.isSharingScreen)
          this.renderer.addClass(element, 'remote-video-sharing-screen');
        this.renderer.appendChild(this.listRef.nativeElement, element);
        //this.participantsChanged.emit(true);
        this.onParticipantsChanged(true);
      }
    }
  }

  private detachRemoteTrack(track: RemoteTrack) {
    if (this.isDetachableRemote(track)) {
      if (track.name == 'screen') {
        this.unsubscribeScreenShare(track);
      } else {
        track.detach().forEach((el) => el.remove());
        //this.participantsChanged.emit(true);
        this.onParticipantsChanged(true);
      }
    }
  }

  private isAttachableRemote(
    track: RemoteTrack
  ): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
      !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined)
    );
  }

  //---------------------------------------------------

  async onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
    await this.camera.initializePreview(deviceInfo.deviceId);
    if (this.settings.isPreviewing) {
      const track = await this.settings.showPreviewCamera();
      if (this.activeRoom) {
        this.localParticipants = this.activeRoom.localParticipant;
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach((publication) =>
          publication.unpublish()
        );
        await localParticipant.publishTrack(track);
      }
    }
  }

  async onLeaveRoom(_: boolean) {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
      this.showPreviewOrSharedScreen = true;
    }

    const videoDevice = this.settings.hidePreviewCamera();
    await this.camera.initializePreview(videoDevice && videoDevice.deviceId);
    this.hasMeetingStarted = false;
    this.participants.clear();
  }

  async onRoomChanged(roomName: VideoRoomDB) {
    if (roomName) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      // this.camera.finalizePreview();

      const tracks = await Promise.all([
        createLocalAudioTrack(),
        this.settings.showPreviewCamera(),
        // this.getScreenTrack(),
      ]);

      this.activeRoom = await this.videoChatService.joinOrCreateRoom(
        this.roomGuid,
        tracks,
        this.participantName
      );

      //To get localvideo in scope
      this.changeDetectorRef.detectChanges();

      //Show local video
      let element = null;
      this.activeRoom.localParticipant.videoTracks.forEach(publication => {
        element = publication.track.attach();
        // this.renderer.addClass(element, 'col-6');
        // this.renderer.addClass(element, 'col-md-4');
        // this.renderer.addClass(element, 'align-self-end');
        //this.renderer.setStyle(element, 'width', '95%');
        //this.renderer.setStyle(element, 'margin-left', '2.5%');

      })
      // this.renderer.appendChild(this.localVideo.nativeElement, element);


      let endTime;
      if (roomName.endTime) {
        //Make EndTime Date Object
        endTime = moment(roomName.endTime);
      } else {
        endTime = moment().add(ConfigConstants.callDuration, 'minutes');
        //endTime.setMinutes(endTime.getMinutes() + ConfigConstants.callDuration);
      }

      //Make UpdateRoomRequest Object
      const room: UpdateRoomRequest = {
        videoCallRequest: {
          subject: roomName.subject,
          sId: this.activeRoom.sid,
          startTime: roomName.startTime
            ? roomName.startTime
            : moment().toISOString(),
          endTime: endTime.toISOString(),
          ownerId: roomName.ownerId,
          videoCallId: roomName.videoCallId,
          callRecording_URL: roomName.callRecording_URL,
          status: roomName.status,
          to: roomName.to
        },
      };

      //Call UpdateRoom API
      const updatedRoom = await this.videoChatService.updateRoom(room);

      //Get timeout of end time in milliseconds
      const timeoutInMs = endTime.valueOf() - moment.utc().valueOf();

      this.countdownConfig = { leftTime: timeoutInMs };
      //this.countdown.begin();
      //TODO: Unhide timer
      this.hasMeetingStarted = true;

      this.showPreviewOrSharedScreen = false;

      this.initialize(this.activeRoom.participants);
      this.registerRoomEvents();

      this.notificationHub.send('RoomsUpdated', true);

      //Schedule function to be executed at end time
      setTimeout(async () => {
        const message = await this.videoChatService.completeMeeting(
          this.activeRoom.sid
        );
        this.onLeaveRoom(true);
      }, timeoutInMs);
    }
  }

  async completeMeeting() {
    const message = await this.videoChatService.completeMeeting(
      this.activeRoom.sid
    );
  }

  async getScreenTrack() {
    const mediaDevices = navigator.mediaDevices as any;
    const stream = await mediaDevices.getDisplayMedia();
    const screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
    return screenTrack;
  }

  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected', (room: Room) => {
        room.localParticipant.tracks.forEach((publication) =>
          this.detachLocalTrack(publication.track)
        );
        this.dialog.open(MessageDialogComponent, {
          data: {
            title: 'Meeting Ended',
            message:
              'Thank you for using our service. This meeting has now ended',
          },
          panelClass: 'd-inline-flex',
          height: '200px'
        });
        this.hasMeetingStarted = false;
      })
      .on('participantConnected', (participant: RemoteParticipant) =>
        this.add(participant)
      )
      .on('participantDisconnected', (participant: RemoteParticipant) =>
        this.remove(participant)
      )
      .on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) =>
        this.loudest(dominantSpeaker)
      );
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach((el) => el.remove());
    }
  }

  private isDetachable(
    track: LocalTrack
  ): track is LocalAudioTrack | LocalVideoTrack {
    return (
      !!track &&
      ((track as LocalAudioTrack).detach !== undefined ||
        (track as LocalVideoTrack).detach !== undefined)
    );
  }

  private isDetachableRemote(
    track: RemoteTrack
  ): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
      !!track &&
      ((track as RemoteAudioTrack).detach !== undefined ||
        (track as RemoteVideoTrack).detach !== undefined)
    );
  }

  private isAttachable(
    track: RemoteTrack
  ): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
      !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined)
    );
  }

  stopScreenShare(track) {
    this.activeRoom.localParticipant.unpublishTrack(track);
  }

  async shareScreen() {
    try {
      const mediaDevices = navigator.mediaDevices as any;
      const stream = await mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          height: 1080,
          width: 1920,
        },
      });

      const track = new LocalVideoTrack(stream.getTracks()[0], {
        name: 'screen',
        logLevel: 'debug',
      });

      stream.getTracks()[0].onended = () => {
        this.activeRoom.localParticipant.unpublishTrack(track);
      };

      await this.activeRoom.localParticipant.publishTrack(track);
    } catch (error) {
      // Don't display an error if the user closes the screen share dialog
      if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
        // Handle other errors if necessary
      }
    }
  }

  onScreenShareChanged(event) {
    if (event) {
      this.showPreviewOrSharedScreen = true;
    } else {
      this.showPreviewOrSharedScreen = false;
    }
  }

  subscribeScreenShare(track: RemoteTrack) {
    if (this.isAttachable(track)) {
      //Remove the loudest participants when screen is shared
      const childElements = this.screen.nativeElement.children;
      for (let child of childElements) {
        this.renderer.removeChild(this.screen.nativeElement, child);
      }
      const element = track.attach();
      this.renderer.data.id = track.sid;

      this.renderer.appendChild(this.screen.nativeElement, element);
      this.isSharingScreen = true;

      //Change all remote videos size to 25% of the screen
      let children = Array.from(this.listRef.nativeElement.children);
      children.forEach((element: HTMLMediaElement) => {
        this.renderer.addClass(element, 'remote-video-sharing-screen');
      });

      //Remove viewport height 100% from remote videos contianer to prevent scrolling
      this.remoteVideosContainer.nativeElement.classList.remove('vh-100')
      this.listRef.nativeElement.classList.remove('vh-100');
    }
  }

  unsubscribeScreenShare(track: RemoteTrack) {
    if (this.isDetachableRemote(track)) {
      track.detach().forEach((el) => el.remove());
      this.isSharingScreen = false;

      //Change all remote videos size back to 100% of the screen
      let children = Array.from(this.listRef.nativeElement.children);
      children.forEach((element: HTMLMediaElement) => {
        this.renderer.removeClass(element, 'remote-video-sharing-screen');
      });
    }
  }


  toggleAudio(event: boolean) {
    const audioTrack = this.activeRoom.localParticipant.audioTracks.values()[0];
    for (const track of this.activeRoom.localParticipant.audioTracks.values()) {
      if (event) {
        track.track.enable();
      } else {
        track.track.disable();
      }
    }
    this.isAudioEnabled = event;
  }

  toggleVideo(event: boolean) {
    if (this.camera.videoTrack.name != 'screen') {
      if (event) {
        this.camera.videoTrack.enable();
      } else {
        this.camera.videoTrack.disable();
      }
    }
    this.isVideoEnabled = event;
  }
}

@Component({
  selector: 'identity-dialog',
  templateUrl: 'identity-dialog.html',
})
export class IdentityDialogComponent {
  name: string;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<IdentityDialogComponent>
  ) { }

  identityForm = this.fb.group({
    name: [null, Validators.required],
  });
}

export interface MessageDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'message-dialog',
  templateUrl: 'message-dialog.html',
})
export class MessageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData
  ) { }

  onOkClick(): void {
    this.dialogRef.close();
  }
}
