<mat-sidenav-container>
  <mat-sidenav mode="over" closed #sidenav1>
    <div class="d-flex justify-content-between border-bottom sidebar-header">
      <div></div>
      <div>
        <button mat-icon-button class="ml-auto" (click)="sidenav1.close()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </div>
    <app-rooms #rooms (roomChanged)="onRoomChanged($event)" [activeRoom]="!!activeRoom ? activeRoom : null"></app-rooms>
    <app-settings #settings (settingsChanged)="onSettingsChanged($event)"></app-settings>
  </mat-sidenav>

  <mat-sidenav mode="over" position="end" closed #sidenav2>
    <div class="d-flex justify-content-between border-bottom sidebar-header">
      <div>
        <button mat-icon-button (click)="sidenav2.close()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
      <div></div>
    </div>
    <app-participants-list [participantsList]="activeRoom?.participants"
      [localParticipantName]="activeRoom?.localParticipant.identity"></app-participants-list>
  </mat-sidenav>

  <mat-sidenav-content class="overflow-hidden">
    <div class="video-call-block">
      <div class="video-call-block-header shadow-sm">
        <img src="../../assets/img/poweredby.png" width="200" />
      </div>

      <div class="container-fluid pt-3">
        <div class="row video-call-content">
          <div class="col-8">
            <div class="card">
              <div class="card-body">
                <div [style.display]="!isSharingScreen ? 'block' : 'none'">
                  <app-camera #camera></app-camera>
                </div>
                <div id="screenOrLoudestParticipant" #screen>
                </div>
                <!-- <app-camera #camera [style.display]="!!activeRoom ? 'none' : 'block'"></app-camera>
              <div class="local-participant" *ngIf="!isSharingScreen" #localVideo></div>
              <div id="screenOrLoudestParticipant" #screen>
              </div> -->
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card">
              <div class="card-body card-scroll-bar">
                <div id="participant-list" *ngIf="activeRoom">
                  <div id="alone" [ngClass]="{ table: isAlone, 'd-none': !isAlone }"
                    style="display: flex; justify-content: center; align-items: center; margin: 150px auto 0px;">
                    <div class="text-center" style="display: block; color: #115caa; font-weight: 500;">
                      <div><i class="far fa-frown" style="font-size: xxx-large;"></i></div>
                      <br />
                      <div>You're currently the sole occupant of this room.</div>
                      <br />
                      <div>As more people join, they'll begin to appear here...</div>
                    </div>
                  </div>
                  <div #remoteVideosContainer>
                    <div class="participants-list" #list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="video-call-block-footer shadow-sm">
        <ul class="video-call-controls">
          <li (click)="toggleAudio(!isAudioEnabled)" [ngClass]="isAudioEnabled ? 'unmute':'mute'">
            <i *ngIf="isAudioEnabled" class="fa-solid fa-microphone"></i>
            <i *ngIf="!isAudioEnabled" class="fa-solid fa-microphone-slash"></i>
          </li>
          <li (click)="toggleVideo(!isVideoEnabled)" [ngClass]="isVideoEnabled ? 'notVideo':'video'">
            <i *ngIf="isVideoEnabled" class="fa-solid fa-video"></i>
            <i *ngIf="!isVideoEnabled" class="fa-solid fa-video-slash"></i>
          </li>
          <li (click)="shareScreen()" [class.disabled]="isAlone">
            <i class="fa-solid fa-share-from-square"></i>
          </li>
          <li (click)="sidenav2.toggle()"><i class="fa-solid fa-users"></i></li>
          <li (click)="sidenav1.toggle()"><i class="fa-solid fa-circle-info"></i></li>
        </ul>
        <button class="btn end-call-btn" [disabled]="!hasMeetingStarted" (click)="onLeaveRoom(true)">End Call</button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>