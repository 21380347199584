export class APIConstants {
  //UAT
  public static BASE_URL = 'https://twilioapi-uat.solzit.com/';

  //DEV
  // public static BASE_URL = 'https://twilioapi-dev-dev.solzit.com/';

  public static readonly GET_ROOM = 'api/V1/Room/GetRoom/';
  public static readonly GET_ROOM_BY_ID = 'api/V1/Room/GetRoomById/';
  public static readonly UPDATE_ROOM = 'api/V1/Room/UpdateRoom/UpdateRoom';
  public static readonly COMPLETE_MEETING = 'api/V1/Room/UpdateRoomStatus/';
}
