<div class="common-popup">
  <div class="common-popup-head">
      <h1>Participant</h1>
      <!-- <i mat-dialog-close class="fa fa-times" aria-hidden="true"></i> -->
  </div>
  <div class="common-popup-body">
    <form [formGroup]="identityForm">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Enter Your Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>
    </form>
  </div>
  <div class="common-popup-footer px-2">
      <div><button mat-raised-button class="mr-2 text-white" style="background: #115caa" [disabled]="identityForm.invalid" [mat-dialog-close]="identityForm.get('name').value">Done</button></div>
  </div>
</div>
