<div id="participant-list">
  <div id="alone" [ngClass]="{ table: isAlone, 'd-none': !isAlone }">
    <div class="col-12">
      <p class="text-center text-monospace h3" style="display: table-cell">
        You're the only one in this room. <i class="far fa-frown"></i>
        <br />
        <br />
        As others join, they'll start showing up here...
      </p>
    </div>
  </div>
  <div [ngClass]="{ 'd-none': isAlone }">
    <div class="row text-center" #list></div>
  </div>
</div>
