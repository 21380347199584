<div class="common-popup">
  <div class="common-popup-head">
    <h1>{{data.title}}</h1>
    <i mat-dialog-close class="fa fa-times" aria-hidden="true"></i>
  </div>
  <div class="common-popup-body">
    <p>{{data.message}}</p>
  </div>
  <div class="common-popup-footer px-4">
    <div>
      <button mat-raised-button (click)="onOkClick()" cdkFocusInitial style="background: #115caa"
        class="text-white mx-auto">
        OK
      </button>
    </div>
  </div>
</div>