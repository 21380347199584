<div class="container-fluid my-3">
  <mat-card class="border">
    <h4>Participants</h4>
    <ul class="list-group">
      <li class="list-group-item">{{ localParticipantName }} (You)</li>
      <li
        class="list-group-item"
        *ngFor="let participant of participantsList | keyvalue"
      >
        {{ participant.value.identity }}
      </li>
    </ul>
  </mat-card>
</div>
